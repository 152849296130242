<template>
  <div class="order-detail">
    <div class="c-width">
      <div class="path">
        当前位置：<i @click="toorder">我的专区 / 我的订单</i> /<span>订单查询</span>
      </div>
      <div class="order-info">
        <div class="cf info-top">
          <div class="h5 fl" v-for="(item,index) in status" :key='index' v-if="item.state == info.status">当前状态：{{item.text}}</div>
          <div class="btn-list fr">
            <li class="t-width fl cf" v-if="info.status != 99">
              <button
                class="btn fr"
                @click="changeDrawing(info.id)"
                v-if="info.status == 0"
              >
                <span>修改图纸</span>
              </button>
              <button
                class="btn pay-btn fr"
                @click="openPay(info.id)"
                v-if="info.status == 2 && info.pay_status == 0 && info.is_offline_pay == 0  && info.is_refuse !== 1"
              >
                <span>立即付款</span>
              </button>
              <p
                      class="fr"
                      style="color: #666"
                      v-if="info.is_offline_pay == 1"
              >
                财务确认中
              </p>
              <button
                class="btn blue fr"
                @click="openBalance(info.id)"
                v-if=" info.pay_status == 2 &&
                    info.is_offline_pay == 0"
              >
                <span>支付尾款</span>
              </button>
              <button
                class="btn wk-btn fr"
                @click="openEvaluate(info.id, info.order_sn)"
                v-if="info.status == 4 || (info.status == 3 && info.pay_status == 1)"
              >
                <span>填写评价</span>
              </button>
              <button
                class="btn fr"
                @click="cancelOrder(info.id)"
                v-if="info.status == 0 || info.status == 1"
              >
                <span>取消订单</span>
              </button>
            </li>
          </div>
        </div>
        <div class="hint-text">订单信息</div>
        <div class="info-detail cf">
          <div class="l-box fl">
            <p>创建时间：{{info.ctime || '暂无数据'}}</p>
            <p>订单编号：{{ info.order_sn || '暂无数据' }}</p>
            <p>客户名称：{{ info.name || '暂无数据' }}</p>
            <p>客户电话：{{ info.phone_num || '暂无数据' }}</p>
            <p>订单金额：{{ info.order_amonut || '暂无数据' }}</p>
            <p>支付金额：{{ info.pay_amonut || '暂无数据' }}</p>
            <p>收货地址：{{ info.addr || '暂无数据' }}</p>
            <p
              v-for="(item, index) in status"
              :key="index"
              v-if="item.state == info.status"
            >
              订单状态：{{ item.text }}
            </p>
          </div>
          <div v-if="info.over_time != ''" class="r-box fr">预计交付时间：{{info.over_time || '暂无数据'}}</div>
        </div>
      </div>

      <div class="step"  v-if="info.status == 3 || info.status == 6">
        <div class="h6">进度信息</div>
        <div class="table">
          <ul class="t-header cf">
            <li
              class="t-item fl"
              v-for="(item, index) in steoHeader"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
          <div v-if="info.delivery_order.length != 0">
          <ul
            class="tb-item cf"
            v-for="(item, index) in info.delivery_order"
            :key="index"
          >
            <li class="t-item fl">{{ item.name || "暂无信息" }}</li>
            <li class="t-item fl">{{ item.invoice_sn || "暂无信息" }}</li>
            <li class="t-item fl">{{ item.goods_nums || "暂无信息" }}</li>
            <li class="t-item fl">{{ item.over_time || "暂无信息" }}</li>
            <li class="t-item fl">{{ item.courier_company || "暂无信息" }}</li>
            <li class="t-item fl">{{ item.courier_num || "暂无信息" }}</li>
            <li class="t-item fl">
              <div class="cf" v-if="info.status == 3">
                <!-- <button class="btn gray">已收货</button> -->
                <span class="sh-hint" v-if="item.status == 5">{{
                  item.after_type == 1 ? "同意返厂维修" : "同意退货退款"
                }}</span>
                <button
                  v-if="item.status == 1 || item.status == 6"
                  class="lj-btn org"
                  @click="imgConfirmGoods(item.id)"
                >
                  确认收货
                </button>
                <p class="sh-hint" v-if="item.status == 2">已收货</p>
                <buttton
                  v-if="item.status == 3"
                  class="sh-sqz"
                >
                  售后申请中
                </buttton>
                  <button
                  v-if="item.status == 6"
                  class="lj-btn"
                  @click="viewRefused(item.id)"
                >
                  查看原因
                </button>
                <button
                  v-if="item.status == 1"
                  class="lj-btn"
                  @click="oepnAfterSales(item.id)"
                >
                  申请售后
                </button>
                <button
                  v-if="item.status == 4"
                  class="wl-btn"
                  @click="
                    oepnWl(item.id, item.after_type, item.category_name, item.child_sn)
                  "
                >
                  填写发货物流信息
                </button>
              </div>
              <div v-else>暂无操作</div>
            </li>
          </ul>
          </div>
          <div v-else class="nofh-text">努力生产中，生产后第一时间给您发货</div>
        </div>
      </div>

      <div class="drawing">
        <div class="h6">图纸信息</div>
        <div class="table">
          <ul class="t-header cf">
            <li class="t-item fl" v-for="(item, index) in drawing" :key="index">
              {{ item }}
            </li>
          </ul>
          <ul
            class="tb-item cf"
            v-for="(item, index) in info.imgs_list"
            :key="index"
          >
            <li class="t-item fl">{{ index + 1 }}</li>
            <li class="t-item fl">{{ item.child_sn || "暂无数据" }}</li>
            <li class="t-item fl">{{ item.name || "暂无数据" }}</li>
            <li class="t-item fl">{{ item.file_format || "暂无数据" }}</li>
            <li class="t-item fl" @click="downFj(item.url)">
              {{ item.new_name }}
            </li>
            <li class="t-item fl">{{ item.version_num || "暂无数据" }}</li>
           <!-- <li class="t-item fl">{{ item.file_size || "暂无数据" }}</li>-->
            <li class="t-item fl">
              {{ item.order_nums || "暂无数据" }}
            </li>
            <li class="t-item fl">
              {{ item.file_content || "暂无数据" }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <pay
      :show="show"
      :type="popType"
      :orderId="order_id"
      @closepop="closepop"
    ></pay>
    <balance
      :show="show"
      :type="popType"
      :orderId="order_id"
      @closepop="closepop"
    ></balance>
    <afterSale
      :show="show"
      :type="popType"
      :orderId="order_id"
      @closepop="closepop"
    ></afterSale>
    <evaluate
      :show="show"
      :type="popType"
      :orderId="order_id"
      :orderNum = "order_num"
      @closepop="closepop"
    ></evaluate>
    <!-- <reason></reason> -->
    <saletype
      :show="show"
      :type="popType"
      :orderId="order_id"
      :shType="shType"
      :jgType="jgType"
      :orderSn="orderSn"
      @closepop="closepop"
    ></saletype>
  </div>
</template>
<script>
import mixins from "../mixins";
import pay from "@/component/popup/pay/src/index";
import balance from "@/component/popup/balance/src/index";
import afterSale from "@/component/popup/after-sale/src/index";
import evaluate from "@/component/popup/evaluate/src/index";
import reason from "@/component/popup/reason/src/index";
import saletype from "@/component/popup/sale-type/src/index";
export default {
  components: {
    pay,
    balance,
    afterSale,
    evaluate,
    reason,
    saletype,
  },
  mixins,
};
</script>
<style scoped>
@import "../style.css";
</style>
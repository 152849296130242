export default{
    data(){
        return{
            fid:1,
            index:0,
            order_amonut:'',
            order_id:'',
            order_num:'',
            order_name:'',
            show:false,
            popType:4,
            state:999,
            shType:'1',
            orderSn:'',
            jgType:'',
            status:[
                {
                    text:'全部',
                    id:1,
                    state:999
                },{
                    text:'待审核',
                    id:2,
                    state:0
                },{
                    text:'待报价',
                    id:3,
                    state:1
                },{
                    text:'待付款',
                    id:4,
                    state:2
                },{
                    text:'待收货',
                    id:5,
                    state:3
                },{
                    text:'已收货',
                    id:6,
                    state:4
                },{
                    text:'已完成',
                    id:7,
                    state:5
                },{
                    text:'售后订单',
                    id:8,
                    state:6
                },{
                    text:'已取消',
                    id:9,
                    state:99
                }
            ],
            theader:['订单号','加工分类','图纸','金额','状态','操作']
        }
    },
    created(){
       
    },
    methods: {
        
        openEvaluate:function(order_id,order_num){
            this.order_id = order_id +'';
            this.order_num = order_num +'';
            this.show = true;
            this.popType = 4;
        },
        openPay:function(order_id){
            this.order_id = order_id +'';
            this.show = true;
            this.popType = 1;
        },
        openBalance:function(order_id){
            this.order_id = order_id +'';
            this.show = true;
            this.popType = 2;
        },
        oepnAfterSales:function(order_id){
            this.order_id = order_id +'';
            this.show = true;
            this.popType = 3;
        },
        closepop:function(){
            this.show = false
            this.getOrderLists(this.state,this.fid);
        },
        oepnWl:function(order_id,shType,jgType,orderSn){
            this.show = true;
            this.order_id = order_id +'';
            this.popType = 5;
            this.shType =shType + '';
            this.orderSn = orderSn +'';
            this.jgType = jgType+'';
        },
        closepop:function(){
            this.show = false
            this.orderDetail(this.$route.query.id);
        },
    }
}
export default{
    data(){
        return{
            info:{},
            order_id:'',
            sub:0
        }
    },
    created() {
        this.order_id = this.$route.query.id
        this.sub = this.$route.query.sub
        this.orderDetail(this.order_id)
    },
    methods: {
        orderDetail:function(id){
            this.$post('/api/order/orderDetail.html', {
                token: true,
                order_id: id,
                sub:this.sub
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.info = res.data.info
                } else {
                    return this.$message(res.msg);
                }

            }).finally(() => {

            })
        },
        changeDrawing: function (order_id) {
            this.$router.push({name:'Order-online',params:{id:order_id}}) 
         },
        downFj:function(url){
            window.open(url)
        },
        cancelOrder: function (order_id) {
            this.$confirm('是否取消该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$post('/api/order/cancelOrder.html', {
                    token: true,
                    order_id: order_id
                }).then((res) => {
                    console.log(res)
                    if (res.code == 200) {
                        this.orderDetail(this.order_id);
                    } else {
                       
                    }
                    return this.$message(res.msg);
                }).finally(() => {

                })
            }).catch(() => {

            });

        },
        viewRefused:function(id){
            this.$post('/api/order/viewRefused.html', {
                token:true,
                id:id
            }).then((res) => {
                if (res.code == 200) {
                    console.log(res.data.refused_content )
                    this.$alert(res.data.refused_content  || '未填写原因，请联系客服', {
                        confirmButtonText: '确定',
                        // callback: action => {
                          
                        // }
                      });
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        toorder:function(){
            this.$router.push({path:'/user-center/order',query:{
                    sub:this.sub
                }})
        }
    }
}